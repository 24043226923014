<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('choiceCoursesManagement') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-select
                            style="width: 300px"
                            @change="fetchClasses()"
                            v-model="currentFeature"
                            :placeholder="$t('select')"
                            :no-data-text="$t('noData')">
                            <el-option
                                v-for="item in features"
                                :key="item.id"
                                :value="item.id"
                                :label="item.label">
                            </el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod" width="50" fixed> </el-table-column>
                <el-table-column sortable :label="$t('name')" prop="name" width="200" fixed> </el-table-column>
                <el-table-column sortable :label="$t('establishment')" prop="establishment" width="140" fixed>
                    <template #default="scope">
                        {{ getEstablishmentLocation(scope.row.establishment) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('level')" prop="level" width="140">
                    <template #default="scope">
                        {{ getLevelName(scope.row.level) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('courses')" prop="coursesNumber" width="120">
                    <template #default="scope">
                        {{ scope.row.coursesNumber || '0' }}
                    </template>
                </el-table-column>

                <el-table-column align="right" width="175" fixed="right">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" type="warning" @click="manage(scope.$index, scope.row)">
                            {{ $t('manage') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog
            fullscreen
            v-model="manageDialog"
            @close="cancelManage()"
            :title="(currentClass ? (currentClass.name + ' - ' + getEstablishmentLocation(currentClass.establishment)) : '') + ' | ' + getFeatureLabel(currentFeature)">

            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('choiceCoursesListAndStudents') }} <span>{{ (rows && rows.length) ?  ('  (' + rows.length + ')') : '' }}</span></h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button @click="addRow()" type="info">{{ $t('add') }}</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <template v-if="currentFeature === Features.SPECIALITY_2.id">

                <el-row :gutter="20" v-for="(row, index) in rows" :key="'row_' + index" >
                    <el-col :span="5">
                        <label class="block-label">{{ $t('course') }}</label>
                        <el-select
                            filterable
                            v-model="row.course"
                            :no-data-text="$t('noData')"
                            :placeholder="$t('selectCourse')"
                            :no-match-text="$t('noMatchingData')"
                            style="width: 100%; margin-top: 5px">
                            <el-option
                                v-for="course in classCourses"
                                :key="course.id"
                                :value="course.id"
                                :label="course.name"
                                :disabled="selectedCourses.findIndex(s => s.id == course.id) > -1">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5">
                        <label class="block-label">{{ $t('participatingStudents') }}</label>
                        <el-select
                            multiple
                            filterable
                            collapse-tags
                            v-model="row.participatingStudents"
                            :no-data-text="$t('noData')"
                            :placeholder="$t('selectStudents')"
                            :no-match-text="$t('noMatchingData')"
                            style="width: 100%; margin-top: 5px">
                            <el-option
                                v-for="student in classStudents"
                                :key="student.id"
                                :value="student.id"
                                :label="student.lastname + ' ' + student.firstname"
                                :disabled="this.rows[index].droppingOutStudents.findIndex(s => s == student.id) > -1">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5">
                        <label class="block-label">{{ $t('droppingOutStudents') }}</label>
                        <el-select
                            multiple
                            filterable
                            collapse-tags
                            v-model="row.droppingOutStudents"
                            :no-data-text="$t('noData')"
                            :placeholder="$t('selectStudents')"
                            :no-match-text="$t('noMatchingData')"
                            style="width: 100%; margin-top: 5px">
                            <el-option
                                v-for="student in classStudents"
                                :key="student.id"
                                :value="student.id"
                                :label="student.lastname + ' ' + student.firstname"
                                :disabled="this.rows[index].participatingStudents.findIndex(s => s == student.id) > -1">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="3">
                        <label class="block-label">{{ $t('coefParticipation') }}</label>
                        <el-input-number
                            v-model="row.participatingCoef"
                            style="width: 100%; margin-top: 5px"
                            controls-position="right"
                            :placeholder="$t('coefParticipation')"
                            :max="100"
                            :min="1"/>
                    </el-col>
                    <el-col :span="3">
                        <label class="block-label">{{ $t('coefAbandonment') }}</label>
                        <el-input-number
                            v-model="row.droppingOutCoef"
                            style="width: 100%; margin-top: 5px"
                            controls-position="right"
                            :placeholder="$t('coefAbandonment')"
                            :max="100"
                            :min="1"/>
                    </el-col>
                    <el-col :span="3">
                        <label class="block-label">{{ $t('actions') }}</label>
                        <el-button @click.prevent="removeRow(row)" style="width: 100%; margin-top: 5px">{{ $t('delete') }}</el-button>
                    </el-col>
                    <el-divider></el-divider>
                </el-row>

            </template>

            <template v-else>

                <el-row :gutter="20" v-for="(row, index) in rows" :key="'row_' + index" >
                    <el-col :span="8">
                        <span class="inline-label">{{ $t('course') }}</span>
                        <el-select
                            filterable
                            v-model="row.course"
                            :no-data-text="$t('noData')"
                            :placeholder="$t('selectCourse')"
                            :no-match-text="$t('noMatchingData')"
                            style="width: 80%">
                            <el-option
                                v-for="course in classCourses"
                                :key="course.id"
                                :value="course.id"
                                :label="course.name"
                                :disabled="selectedCourses.findIndex(s => s.id == course.id) > -1">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <span class="inline-label">{{ $t('students') }}</span>
                        <el-select
                            multiple
                            filterable
                            collapse-tags
                            v-model="row.participatingStudents"
                            :no-data-text="$t('noData')"
                            :placeholder="$t('selectStudents')"
                            :no-match-text="$t('noMatchingData')"
                            style="width: 80%">
                            <el-option
                                v-for="student in classStudents"
                                :key="student.id"
                                :value="student.id"
                                :label="student.lastname + ' ' + student.firstname">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5">
                        <span class="inline-label" style="width: 50%">{{ $t('coef') }}</span>
                        <el-input-number
                            v-model="row.participatingCoef"
                            style="width: 50%"
                            controls-position="right"
                            :placeholder="$t('coef')"
                            :max="100"
                            :min="1"/>
                    </el-col>
                    <el-col :span="3">
                        <el-button @click.prevent="removeRow(row)" style="width: 100%">{{ $t('delete') }}</el-button>
                    </el-col>
                    <el-divider></el-divider>
                </el-row>

            </template>


            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelManage()">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitManage()">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { Features } from '@/utils/enums'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'ChoiceCourses',
    mixins: [accessManager],
    data() {
        return {
        Features,
        rows: [],
        levels: [],
        courses: [],
        features: [],
        departments: [],
        classCourses: [],
        classStudents: [],
        establishments: [],
        currentFeature: Features.EXPLORATION.id,
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        manageDialog: false,
        currentClass: null,
        isLoading: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
        }
    },
    created() {

        this.fetchClasses()

        var self = this

        this.$_.forIn(Features, function(value, key) {

            self.features.push(value)

        })

    },
    computed:{
        ...mapState({
        userData: state => state.session.userData
        }),
        ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
        }),
        pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

        },
        searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()))

        },
        selectedCourses() {

        return this.classCourses.filter(course => this.rows.findIndex(r => r.course == course.id) > -1)

        }
    },
    methods: {
        ...mapActions({
            fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
            fetchChoiceCoursesDB: 'choicecourses/fetchChoiceCoursesDB',
            fetchDepartmentsDB: 'departments/fetchDepartmentsDB',
            fetchRepartitionDB: 'repartition/fetchRepartitionDB',
            fetchStudentsDB: 'students/fetchStudentsDB',
            fetchCoursesDB: 'courses/fetchCoursesDB',
            fetchClassesDB: 'classes/fetchClassesDB',
            fetchLevelsDB: 'levels/fetchLevelsDB',
        }),
        setPage (val) {

            this.page = val

        },
        indexMethod(index) {

            return index + ((this.page - 1) * this.pageSize) + 1

        },
        getLevelName(id){

            let level = this.levels.find(l => l.id == id)

            return level ? level.name : ''

        },
        getDepartmentName(id){

            let department = this.departments.find(d => d.id == id)

            return department ? department.name : ''

        },
        getEstablishmentLocation(id){

            let establishment = this.establishments.find(e => e.id == id)

            return establishment ? establishment.location : ''

        },
        getFeatureLabel(id){

            let feature = this.features.find(f => f.id == id)

            return feature ? feature.label : ''

        },
        getFeatures(str){

            let tabs = []
            let parts = str.split(',')

            parts.forEach(part => {
            let feature = this.features.find(f => f.id == part)

            if (feature) tabs.push(feature.label)
            })

            return tabs

        },
        async fetchClasses(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp5 = await this.fetchCoursesDB({
            action: 'getCourses',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp5.success && resp5.data) this.courses = resp5.data

        let resp4 = await this.fetchEstablishmentsDB({
            action: 'getEstablishments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp4.success && resp4.data) this.establishments = resp4.data

        let resp1 = await this.fetchDepartmentsDB({
            action: 'getDepartments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp1.success && resp1.data) this.departments = resp1.data

        let resp2 = await this.fetchLevelsDB({
            action: 'getLevels',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp2.success && resp2.data) this.levels = resp2.data

        let resp3 = await this.fetchClassesDB({
            action: 'getClassesByFeature',
            params: {
                feature: this.currentFeature,
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp3.success){

            if (resp3.data) {

                this.tableData = []

                resp3.data.forEach(v => {
                    this.tableData.push(v)
                })

            }

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.fetchCoursesNumber()

        this.isLoading = false

        },
        async fetchCoursesNumber(){

            for (let i = 0; i < this.tableData.length; i++) {

                let resp = await this.fetchRepartitionDB({
                    action: 'getClassCourses',
                    params: {
                    class: this.tableData[i].id,
                    schoolyear: this.adminSchoolyear
                    }
                })

                if (resp.success && resp.data) this.tableData[i].coursesNumber = resp.data.length
                else this.tableData[i].coursesNumber = 0

            }

        },
        removeRow(row){

        var index = this.rows.indexOf(row)

        if (index !== -1) this.rows.splice(index, 1)

        },
        addRow(){

        this.rows.push({
            course: '',
            participatingStudents: [],
            droppingOutStudents: [],
            participatingCoef: 0,
            droppingOutCoef: 0
        })

        },
        async manage(index, item){

        if (this.lockedData){
            this.$message.warning(this.$t('lockedData'))

            return
        }

        this.currentClass = item

        let resp = await this.fetchRepartitionDB({
            action: 'getClassCourses',
            params: {
                class: item.id,
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data && resp.data.length){

                this.classCourses = this.courses.filter(course => resp.data.findIndex(data => data.course == course.id) > -1)

                let resp1 = await this.fetchStudentsDB({
                    action: 'getStudentsByClass',
                    params: {
                        class: item.id,
                        schoolyear: this.adminSchoolyear
                    }
                })

                if (resp1.success) {

                    if (resp1.data && resp1.data.length){

                        this.classStudents = resp1.data

                        let resp2 = await this.fetchChoiceCoursesDB({
                            action: 'getClassFeatureCourses',
                            params: {
                                class: item.id,
                                feature: this.currentFeature,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (resp2.success){

                            if (resp2.data) {

                                let data = []

                                resp2.data.forEach(v => {

                                    let id = data.findIndex(d => d.course == v.course)
                                    if (id > -1){

                                        if (parseInt(v.participating)) {

                                            data[id].participatingStudents.push(v.student)
                                            data[id].participatingCoef = parseInt(v.coef)

                                        } else {

                                            data[id].droppingOutStudents.push(v.student)
                                            data[id].droppingOutCoef = parseInt(v.coef)

                                        }

                                    }else{

                                        if (parseInt(v.participating)){

                                            data.push({
                                                course: v.course,
                                                participatingStudents: [v.student],
                                                droppingOutStudents: [],
                                                participatingCoef: parseInt(v.coef),
                                                droppingOutCoef: 0
                                            })

                                        }else{

                                            data.push({
                                                course: v.course,
                                                participatingStudents: [],
                                                droppingOutStudents: [v.student],
                                                participatingCoef: 0,
                                                droppingOutCoef: parseInt(v.coef)
                                            })

                                        }

                                    }

                                })

                                data.forEach(v => {

                                    this.rows.push({
                                        course: v.course,
                                        droppingOutStudents: v.droppingOutStudents,
                                        participatingStudents: v.participatingStudents,
                                        participatingCoef: parseInt(v.participatingCoef),
                                        droppingOutCoef: parseInt(v.droppingOutCoef)
                                    })

                                })

                            }

                            this.manageDialog = true

                        }else{

                            this.$message.error(this.$t('anErrorOccured'))

                        }

                    }else{

                        this.$message.warning(this.$t('noStudentInThisClass'))

                    }

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            }else{

                this.$message.warning(this.$t('noCourseInThisClass'))

            }

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        },
        async submitManage(){

        if (this.rows && this.currentClass){

            let filteredRows = this.rows.filter(r => (r.course))

            if (filteredRows && filteredRows.length){

                let task = await this.fetchChoiceCoursesDB({
                    action: 'updateChoiceCourses',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        class: this.currentClass.id,
                        feature: this.currentFeature,
                        data: filteredRows
                    }
                })

                if (task.success) this.$message.success(this.$t('success'))
                else this.$message.error(this.$t('anErrorOccured'))

            } else {

                await this.fetchChoiceCoursesDB({
                    action: 'updateChoiceCourses',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        class: this.currentClass.id,
                        feature: this.currentFeature,
                        data: 'clear'
                    }
                })

            }

            this.fetchCoursesNumber()

        }

        this.cancelManage()

        },
        cancelManage(){

        this.manageDialog = false
        this.currentClass = null
        this.rows = []

        }
    },
}
</script>

<style scoped>
    .inline-label{
        width: 20%;
        font-weight: bold;
        display: inline-block;
    }

    .block-label{
        font-weight: normal;
        font-size: .9em;
    }
</style>